<template>
  <article :style="blockStyles">
    <span v-if="note.featured_image" class="ag-block__featured-image">
      <Image :media="note.featured_image" :alt="note.title" conversion="square-thumb" width="100" lazy />
    </span>

    <div class="ag-block__body">
      <h3 v-if="note.title" class="ag-block__title" v-html="$markdown(note.title)" />
      <p v-if="note.synopsis" class="ag-block__description" v-html="note.synopsis"></p>
      <div v-if="note.url && note.call_to_action_text" class="ag-block__cta">
        <Button :href="note.url" :style="buttonStyles" :target="target" as="a" class="ag-block__button" rel="nofollow noindex" @click.prevent="onClick(block)">
          {{ note.call_to_action_text }}
        </Button>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
const props = defineProps<{
  block: Block;
}>();

const note: NoteBlock = props.block.item;

const target = note?.url_type === "link" ? "_blank" : undefined;

const blockStyles = computed(() => (note.accent_color ? { backgroundColor: note.accent_color } : null));
const buttonStyles = computed(() => (note.accent_color ? { color: note.accent_color } : null));

const { onClick } = useBlock();
</script>

<style lang="postcss">
.ag-block--note-simple2 {
  @apply overflow-hidden rounded-lg p-8;

  .ag-block__featured-image {
    @apply mr-8 flex-shrink-0;
  }

  .ag-block__title,
  .ag-block__title a {
    @apply text-white;
  }

  .ag-block__description {
    @apply text-white text-opacity-75;

    a {
      @apply font-medium text-white text-opacity-90;

      &:hover {
        @apply text-white text-opacity-100;
      }
    }
  }

  .ag-block__body {
    @apply w-full;
  }

  &.ag-block--has-featured-image {
    @apply flex;
  }
}
</style>
